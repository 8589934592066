.row.line {
}

.tr_white {
  color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 767px) {
  .row.line {
    border-bottom: 1px solid #ffffff40;
  }

  .table-like li.line .cell {
    width: calc(100% - 24px);
    padding: 2px 12px !important;
  }
}
