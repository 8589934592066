.array_title {
  padding: 0 16px;
}

.nbg-hub.nbg-hub-forum-topics .array_descr {
  margin: 14px 0;
}

.taxonomy.clicked.icon-arrow_up:before {
  margin: 0 4px;
}
.taxonomy.clicked.icon-arrow_down:before {
  color: white;
}
