.pagination {
  font-family: "Oswald";
  width: 100%;
  margin: 0px auto 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
}

.pagination ul {
  padding: 0;
  margin: 0 auto;
}

.pagination ul li {
  display: inline-block;
  float: left;
  margin: 4px 2px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.13);
  width: 20px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}

.pagination ul li a {
  /* margin: 10px; */
  padding: 14px 0;
  color: #000;
}

.pagination ul li:nth-child(1),
.pagination ul li:nth-last-child(1) {
  background: none;
}

.pagination ul li:nth-child(1),
.pagination ul li:nth-child(2),
.pagination ul li:nth-last-child(2),
.pagination ul li:nth-last-child(1) {
  margin: 6px 2px;
  padding: 4px;
  font-size: 15px;
}

.pagination ul li:nth-child(1) {
  width: 70px;
}

.pagination ul li:nth-child(1) {
  position: relative;
  overflow: hidden;
}

.pagination ul li:nth-child(1):before,
.pagination ul li:nth-child(1):after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}

.pagination ul li:nth-child(1):before {
  left: 0px;
  top: 0px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(-45deg);
  -ms-transform: skewY(-45deg);
  transform: skewY(-45deg);
}

.pagination ul li:nth-child(1):after,
.pagination ul li:nth-child(1):before {
  background: rgba(255, 255, 255, 0.13);
}

.pagination ul li:nth-child(1):after {
  right: 0;
  width: calc(100% - 20px);
  border-left: none;
}

.pagination ul li:nth-child(2) {
  width: 100px;
}

.pagination ul li:nth-last-child(2) {
  width: 100px;
}

.pagination ul li:nth-last-child(1) {
  width: 70px;
}

.pagination ul li:nth-last-child(1) {
  position: relative;
  overflow: hidden;
}

.pagination ul li:nth-last-child(1):before,
.pagination ul li:nth-last-child(1):after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}

.pagination ul li:nth-last-child(1):before {
  right: 20px;
  width: calc(100% - 20px);
  border-left: none;
}

.pagination ul li:nth-last-child(1):after,
.pagination ul li:nth-last-child(1):before {
  background: rgba(255, 255, 255, 0.13);
}

.pagination ul li:nth-last-child(1):before,
.pagination ul li:nth-last-child(1):after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}

.pagination ul li:nth-last-child(1):after {
  right: 0px;
  top: -20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(-45deg);
  -ms-transform: skewY(-45deg);
  transform: skewY(-45deg);
}

.pagination .active {
  color: #fff;
  cursor: not-allowed;
}

.pagination .blocked {
  cursor: initial !important;
  background: rgba(128, 128, 128, 0.7);
}

.pagination ul li.blocked:nth-child(1):after,
.pagination ul li.blocked:nth-child(1):before {
  background: rgba(128, 128, 128, 0.7) !important;
}

.pagination ul li.blocked:nth-last-child(1):after,
.pagination ul li.blocked:nth-last-child(1):before {
  background: rgba(128, 128, 128, 0.7) !important;
}

@media screen and (max-width: 767px) {
  .pagination {
    width: 280px;
    padding-top: 45px;
    margin-top: 10px;
  }

  .pagination ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .pagination ul li:nth-child(1) {
    -webkit-box-ordinal-group: 102;
    -ms-flex-order: 101;
    order: 101;
  }

  .pagination ul li:nth-child(2) {
    -webkit-box-ordinal-group: 103;
    -ms-flex-order: 102;
    order: 102;
  }

  .pagination ul li:nth-last-child(2) {
    -webkit-box-ordinal-group: 104;
    -ms-flex-order: 103;
    order: 103;
  }

  .pagination ul li:nth-last-child(1) {
    -webkit-box-ordinal-group: 105;
    -ms-flex-order: 104;
    order: 104;
  }

  .pagination ul li:nth-child(1),
  .pagination ul li:nth-child(2),
  .pagination ul li:nth-last-child(2),
  .pagination ul li:nth-last-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 calc(25% - 12px);
    flex: 0 1 calc(25% - 12px);
    width: initial !important;
  }
}
