/*!* MESSAGE-BOX *!*/

/*.message-box {*/
/*    padding: 0 10px 1rem 10px;*/
/*    border-radius: 6px;*/
/*    margin: 2rem 0;*/
/*    color: black;*/
/*    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
/*    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
/*    }*/

/*.message-box__top {*/
/*    padding-top: .5rem;*/
/*    border-bottom: 2px solid #006E80;*/
/*    }*/

/*.message-box__top::after {*/
/*    display: table;*/
/*    content: '';*/
/*    clear: both;*/
/*    }*/

/*.message-box__title {*/
/*    font-weight: 700;*/
/*    color: #006E80;*/
/*    !*font-size: 1.4em;*!*/
/*    float: left;*/
/*    }*/

/*h1.message-box__title {*/
/*    font-size: 1.4rem;*/
/*    }*/

/*.message-box__subtitle {*/
/*    float: right;*/
/*    color: #9B9B9B;*/
/*    }*/

/*.message-box__body {*/
/*    font-size: 1rem;*/
/*    line-height: 1.5;*/
/*    padding: 8px;*/
/*    margin: 0;*/
/*    }*/

/*!* MODIFIERS *!*/

/*.message-box--answer {*/
/*    margin-left: 10%;*/
/*    background: rgba(0, 110, 128, 1);*/
/*    color: white;*/
/*    position: relative;*/
/*    }*/

/*.message-box--answer .message-box__title,*/
/*.message-box--answer .message-box__subtitle {*/
/*    color: white;*/
/*    }*/

/*.message-box--answer .message-box__top {*/
/*    border-bottom: 2px solid #F8E71C;*/
/*    }*/

/*.message-box--answer::before {*/
/*    content: '';*/
/*    background: url("../../../src/assets/assets/images/ibank.png") no-repeat;*/
/*    background-size: contain;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 50px;*/
/*    -webkit-transform: translateY(-50%) translateX(-100%);*/
/*    -ms-transform: translateY(-50%) translateX(-100%);*/
/*    transform: translateY(-50%) translateX(-100%);*/
/*    margin-left: -2.5%;*/
/*    font-size: 2rem;*/
/*    font-weight: 700;*/
/*    }*/

/*@media screen and (max-width: 450px) {*/
/*    .message-box--answer::before {*/
/*        width: 30px;*/
/*        }*/
/*    }*/

/* ------------------------------------- */

.support.main_comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 0;
}

.support {
  font-family: "CF Asty Std";
  /*font-weight: 900;*/
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
  width: 100%;
  position: relative;
}

.support:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  /* border-right: 20px solid transparent; */
  border-top: 20px solid rgba(255, 255, 255, 0.22);
  /* border-bottom: 20px solid #1a4663; */
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  right: 0px;
  top: 0px;
}

.title_table {
  display: table;
  width: 100%;
}
.support.comment_details {
  display: inline-block;
  float: left;
  width: 140px;
  padding: 0 10px;
}

.support_comment_user {
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 500;
}

.support_comment_date {
  text-align: left;
  font-size: 12px;
  color: #fff77d;
}

.support.comment_body {
  display: inline-block;
  float: left;
  width: calc(100% - 180px);
  padding: 0 10px;
}

.comment_txt {
  font-size: 13px;
  padding: 20px 20px;
  background: #ffffff08;
  /* margin: 0; */
  border-bottom: 6px solid rgba(255, 255, 255, 0.22);
}
.comment_txt div {
  color: white;
}

.message--answer {
  margin-left: 10%;
  color: white;
  position: relative;
}

.message--answer::before {
  content: "";
  background-size: contain;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 50px;
  -webkit-transform: translateY(-50%) translateX(-100%);
  -ms-transform: translateY(-50%) translateX(-100%);
  transform: translateY(-50%) translateX(-100%);
  margin-left: -2.5%;
  font-size: 2rem;
  font-weight: 700;
}

.message--answer .support_comment_user {
  color: #fff77d;
}

.message--answer .support_comment_date {
  color: #33b3bf;
}

@media screen and (max-width: 767px) {
  .support.main_comment {
    display: inline-block;
    padding: 20px 0 10px 0;
  }
}
